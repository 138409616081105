import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { oembed } from "@loomhq/loom-embed";

export default class extends Controller {
  static targets = ["form", "input", "editor", "filterMode", "month", "year", "container"];

  connect() {
    if (document.getElementById("bdr-dashboard")) {
      this.initializeDatePicker();
    }

    this.hideAllTrixToolbars();
    this.initializeTrixEditors();
    this.element.addEventListener("trix-change", this.replaceHyphenWithBullet.bind(this));

    this.loadLoomVideo();
  }

  showTrixToolbar(toolbarId) {
    const toolbar = document.getElementById(toolbarId);
    if (toolbar) {
      toolbar.style.display = "block";
    }
  }

  hideTrixToolbar(toolbarId) {
    const toolbar = document.getElementById(toolbarId);
    if (toolbar) {
      toolbar.style.display = "none";
    }
  }

  initializeTrixEditors() {
    this.editorTargets.forEach((editor) => {
      const toolbarId = editor.getAttribute("toolbar");
      editor.addEventListener("trix-focus", () => this.showTrixToolbar(toolbarId));
      editor.addEventListener("trix-blur", () => this.hideTrixToolbar(toolbarId));
    });
  }

  hideAllTrixToolbars() {
    this.editorTargets.forEach((editor) => {
      const toolbarId = editor.getAttribute("toolbar");
      const toolbar = document.getElementById(toolbarId);
      if (toolbar) {
        toolbar.style.display = "none";
      }
    });
  }

  initializeDatePicker() {
    const savedDate = this.inputTarget.value || "today";
    const filterMode = this.filterModeTarget.value || "month";

    if (this.datepicker) {
      this.datepicker.destroy(); // Destroy previous instance to avoid conflicts
    }

    this.datepicker = flatpickr(this.inputTarget, {
      defaultDate: savedDate,
      dateFormat: this.getDateFormat(filterMode),
      onChange: this.dateChanged.bind(this, filterMode),
    });
  }

  dateChanged(filterMode, selectedDates, dateStr) {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");

    // Remove the last two segments (filter mode and date)
    const baseUrl = urlParts.slice(0, -2).join("/");

    // Construct the new URL based on the filterMode and selected date
    let newUrl;

    switch (filterMode) {
      case "week":
        const week = this.getWeekNumber(selectedDates[0]);
        const year = selectedDates[0].getFullYear();
        newUrl = `${baseUrl}/week/${year}-${week}`;
        break;
      default:
        const monthYear = dateStr; // dateStr already contains year-month in the correct format
        newUrl = `${baseUrl}/month/${monthYear}`;
        break;
    }

    // Redirect to the new URL
    window.location.href = newUrl;
  }

  getWeekNumber(date) {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  }

  getDateFormat(filterMode) {
    switch (filterMode) {
      case "week":
        return "Y-W"; // Year and Week
      default:
        return "Y-m"; // Year and Month
    }
  }

  changeFilterMode() {
    this.initializeDatePicker();
  }

  updateReportPath(event) {
    event.preventDefault();
    const url = event.currentTarget.href;

    // This block directly fetch the HTML and replace the content without reloading the page
    // And then hide trix toolbars and reinitialize the trix editors
    fetch(url, {
      headers: {
        "Turbo-Frame": "report_form"
      }
    })
    .then(response => response.text())
    .then(html => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const newContent = doc.querySelector("#report_form").innerHTML;

      const reportForm = document.querySelector("#report_form");
      reportForm.innerHTML = newContent;

      // Reinitialize after updating the content
      this.hideAllTrixToolbars();
      this.initializeTrixEditors();

      this.loadLoomVideo();
    });

    this.highlightSelectedCell(event.currentTarget);
    history.pushState({}, "", url);
  }

  highlightSelectedCell(linkElement) {
    const selectedCell = linkElement.closest("td");

    const calendarCells = document.querySelectorAll("td.has-events");
    calendarCells.forEach(cell => {
        cell.style.backgroundColor = "";
    });

    // Highlight the selected cell
    if (selectedCell) {
        selectedCell.style.backgroundColor = "#69c5f2";
    }
  }

  selectMonthYear() {
    if (typeof this.element.requestSubmit === "function") {
      this.element.requestSubmit();
    } else {
      this.element.submit();
    }
  }

  replaceHyphenWithBullet(event) {
    const editor = event.target.editor;
    const range = editor.getSelectedRange();
    const text = editor.getDocument().toString();
    const position = range[0];

    const precedingText = text.slice(position - 2, position);

    if (precedingText === "- ") {
      editor.setSelectedRange([position - 2, position]);
      editor.deleteInDirection("forward");
      editor.insertString("• ");
    }
  }

  async embedLoom(event) {
    // Document: https://dev.loom.com/docs/embed-sdk/api#oembed
    const loomVideoUrl = event.target.value;
    const loomVideoContainer = this.containerTarget;

    // Clear any existing embed
    loomVideoContainer.innerHTML = '';

    if (loomVideoUrl) {
      try {
        const options = {
          width: 495,
          height: 300,
          gifThumbnail: true
        };

        // Use the Loom SDK's oembed function with the options
        const embedData = await oembed(loomVideoUrl, options);

        if (embedData && embedData.html) {
          loomVideoContainer.innerHTML = embedData.html;
        } else {
          loomVideoContainer.innerHTML = '<p>Unable to embed the video. Please check the URL.</p>';
        }
      } catch (error) {
        loomVideoContainer.innerHTML = '<p>Error embedding the video.</p>';
      }
    }
  }

  loadLoomVideo() {
    const loomVideoInput = document.getElementById("bench_daily_report_loom_video_url");
    if (loomVideoInput && loomVideoInput.value) {
      this.embedLoom({ target: { value: loomVideoInput.value } });
    }
  }
}