import { Controller } from "@hotwired/stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = ["form", "status", "input", "currentWorkCharCount"];
  static values = { postId: Number, reportId: Number, maxLength: Number };

  connect() {
    if (document.getElementById("report-form")) {
      this.loadBdrDraftStates();
      this.updateCharCount();
    }

    if (document.getElementById("new-post-form") || document.getElementById("edit-post-form")) {  
      this.loadPostDraftStates();
    }
  }

  loadPostDraftStates() {
    this.timeout = null;
    this.timeoutDuration = 1500;

    var trixEditor = document.getElementById("post_content");
    this.title = document.getElementById("post_title").value;
    this.content = trixEditor.innerHTML;

    this.collectionIds = [];
    var selectedCollections = document.getElementById("post_collection_ids").selectedOptions;
    for (let i = 0; i < selectedCollections.length; i++) {
      this.collectionIds.push(selectedCollections[i].value);
    }

    // hide status line
    this.statusTarget.hidden = true;

    // save draft when attachment is added 
    trixEditor.addEventListener("trix-change", this.onInputContent.bind(this))
  }

  loadBdrDraftStates() {
    this.timeout = null;
    this.timeoutDuration = 1500;

    let currentWorkContent = document.getElementById("bench_daily_report_current_work");
    let goalsContent = document.getElementById("bench_daily_report_goals");
    let goalsPlanContent = document.getElementById("bench_daily_report_goals_plan");
    let date = document.getElementById("bench_daily_report_date");
    let loomVideoUrl = document.getElementById("bench_daily_report_loom_video_url");
    this.currentWork = currentWorkContent.innerHTML;
    this.goals = goalsContent.innerHTML;
    this.goalsPlan = goalsPlanContent.innerHTML;
    this.date = date.value;
    this.loomVideoUrl = loomVideoUrl.value;

    // hide status line
    this.statusTarget.hidden = true;

    // save draft when attachment is added 
    currentWorkContent.addEventListener("trix-change", this.updateProperty.bind(this));
    goalsContent.addEventListener("trix-change", this.updateProperty.bind(this));
    goalsPlanContent.addEventListener("trix-change", this.updateProperty.bind(this));
  }

  savePostDraft() {
    clearTimeout(this.timeout);
    this.draftData = {
      post: {
        id: this.postIdValue == 0 ? null : this.postIdValue,
        collection_ids: this.collectionIds,
        title: this.title,
        content: this.content
      }
    }
    this.timeout = setTimeout(() => {
      this.statusTarget.hidden = false;
      this.statusTarget.textContent = "Saving draft...";
      this.request(this.draftData, `/posts/draft`);
    }, this.timeoutDuration);
  }

  saveBdrDraft() {
    clearTimeout(this.timeout);
    this.draftData = {
      bench_daily_report: {
        id: this.reportIdValue == 0 ? null : this.reportIdValue,
        current_work: this.currentWork,
        goals: this.goals,
        goals_plan: this.goalsPlan,
        date: this.date || this.getDateFromUrl(),
        loom_video_url: this.loomVideoUrl
      }
    }
    this.timeout = setTimeout(() => {
      this.statusTarget.hidden = false;
      this.statusTarget.textContent = "Saving draft...";
      this.request(this.draftData, `/bench_daily_reports/draft`);
    }, this.timeoutDuration);
  }

  request(draftData, draftUrl) {
    this.headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
    }

    axios.post(draftUrl, draftData, { headers: this.headers })
      .then(() => {
        var time = new Date();
        this.statusTarget.textContent = `Draft last saved at ${time.toLocaleTimeString()}, on ${time.toLocaleDateString()}`;
      })
      .catch(() => {
        this.statusTarget.textContent = "Draft could not be saved. Please try again."
      });
  }

  onSelectCollection(event) {
    this.collectionIds = event.selectedValues;
    this.savePostDraft();
  }

  onInputTitle(event) {
    this.title = event.target.value;
    this.savePostDraft();
  }

  onInputContent(event) {
    this.content = event.target.value;
    this.savePostDraft();
  }

  // BDR
  updateProperty(event) {
    const propertyName = event.target.dataset.draftProperty;
    this[propertyName] = event.target.value;
    this.saveBdrDraft();
  }

  getDateFromUrl() {
    let path = window.location.pathname;
    // Remove leading and trailing slashes and split the path into segments
    let pathSegments = path.replace(/^\/|\/$/g, '').split('/');
    // Find a valid date
    for (let segment of pathSegments) {
      // Check if the segment is in the format YYYY-MM-DD
      if (/^\d{4}-\d{2}-\d{2}$/.test(segment)) {
        return segment;
      }

      if (segment == "today") {
        return this.formatDate(new Date());
      }
    }

    return null;
  }

  formatDate(date) {
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  updateCharCount() {
    const textContent = this.inputTarget.innerHTML.replace(/<[^>]+>/g, '').trim();
    const remaining = 100 - textContent.length;
    this.currentWorkCharCountTarget.textContent = `${remaining} characters remaining`;
  }
}
